import * as React from "react"
import styled from 'styled-components';
import { Link } from "gatsby"
import { ArrowLeft } from 'react-feather';


const ProjectHeader = (props) => {
  return (
    <Grid>
      <ButtonGrid>
        <StyledLink to="/">
          <ArrowLeft />
          Back
        </StyledLink>
      </ButtonGrid>
      <h1 className="header__title">{props.title}</h1>
      <p className="header__sub-title">{props.subTitle}</p>
    </Grid>
  )
}

export default ProjectHeader


const Grid = styled.header`
  margin-top: 48px;
  display: grid;
  @media (max-width: 1200px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: 850px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: 650px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .header__title {
    font-weight: 800;
    color: var(--black-blue);
    margin-bottom: clamp( 18px, 2vw, 32px);
    font-size: clamp(100%, 38px + 2vw, 72px);
    @media (max-width: 750px) {
      margin-bottom: 8px;
    }
  }
  .header__sub-title {
    font-style: normal;
    font-weight: normal;
    color: var(--black-blue);
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 16px;
    font-size: clamp( 18px, 2vw, 24px);
  }
`

const StyledLink = styled(props => <Link {...props} />)`
    background: var(--gray);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    ${'' /* width: 133px; */}
    text-align: center;
    text-decoration: none;
    margin-bottom: 64px;
    margin-bottom: clamp( 24px, 2vw, 64px);
    
    display: flex;
    justify-content: center;
    ${'' /* Change to this when you come back to fix
    justify-content: space-around; */}

    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 12px;

    svg {
      padding: 2px;
    }
    transition: transform 200ms,background 200ms;
    transition-property: transform, background;
    transition-duration: 200ms, 200ms;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    
    :hover {
      transform: translateY(-2px);
      background-color: #75818E;
    }
`;

const ButtonGrid = styled.div`
  display: grid;
  width: 10%;
`