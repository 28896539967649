import * as React from "react"
import styled from 'styled-components';
import Emoji from 'a11y-react-emoji'

//Components


const ProjectInfo = (props) => {
  return (
    <Grid>

        <div className="project-info__when">
        <StyledEmoji symbol="🗓" label="spiral calendar" />
          <p>
            <span>When: </span>
            {props.when}
          </p>
        </div>
        <div className="project-info__platform">
        <StyledEmoji symbol={props.symbolPlatform} label="laptop" />
          <p>
            <span>Platform: </span>
            {props.platform}
          </p>
        </div>
        <div className="project-info__team">
        <StyledEmoji symbol="👥" label="silhouette of two people" />
          <p>
            <span>Team: </span>
            {props.team}
          </p>
        </div>
        <div className="project-info__role">
        <StyledEmoji symbol="👨‍💻" label="man technologist" />
          <p>
            <span>Role: </span>
            {props.role}
          </p>
        </div>
        <div className="project-info__tools">
        <StyledEmoji symbol="🛠" label="hammer and wrench" />
          <p>
            <span>Tools: </span>
            {props.tools}
          </p>
        </div>

    </Grid>
  )
}

export default ProjectInfo

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 32px;
  margin-top: 32px;
  grid-auto-flow:dense;
  @media (max-width: 750px) {
      grid-gap: 8px;
    }

  @media (max-width: 1200px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: 850px) {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media (max-width: 650px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  div {
    background: rgba(221, 221, 221, 0.11);
    /* background: rgba(196, 196, 196, 0.11); */
    border: .1rem solid #c7c7cc;
    box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
    box-sizing: border-box;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 750px) {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    display: flex;
    align-items: center;
  }
  p {
    color: var(--black-blue);
    font-weight: 600;
    padding-left: 16px;
    font-size: clamp( 14px, 2vw, 16px);
    span {
      font-weight: normal;
    }

  }

  .project-info__when {
    grid-area: 1 / 1 / 2 / 2;

  }
  .project-info__platform {
    grid-area: 1 / 0 / 1 / 1;

  }
  .project-info__team {
    grid-area: 2 / 1 / 2 / 2;

  }
  .project-info__role {
    grid-area: 2 / 0 / 2 / 1;

  }
  .project-info__tools {
    grid-area: 3 / 1 / 3 / span 2;

  }


  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

    .project-info__when {
    grid-area: 1 / 1 / 1 / 5;

    }
    .project-info__platform {
      grid-area: 2 / 1 / 2 / 5;

    }
    .project-info__team {
      grid-area: 3 / 1 / 3 / 5;

    }
    .project-info__role {
      grid-area: 4 / 1 / 4 / 5;

    }
    .project-info__tools {
      grid-area: 5 / 1 / 5 / 5;

    }
  }

`

const StyledEmoji = styled(({  ...props }) => <Emoji {...props} />)`
    font-size: 24px;
    padding-left: 16px;

`