import * as React from "react"
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';

import ProjectSubHeaderBlank from "../../components/ProjectSubHeaderBlank";
import FooterWithPadding from '../../components/FooterWithPadding';

const PointOfCarePage = () => {

  const data = useStaticQuery(graphql`
    query {

      pocIosOne: file(relativePath: { eq: "poc/ios/poc-ios-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosTwo: file(relativePath: { eq: "poc/ios/poc-ios-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosThree: file(relativePath: { eq: "poc/ios/poc-ios-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosFour: file(relativePath: { eq: "poc/ios/poc-ios-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosFive: file(relativePath: { eq: "poc/ios/poc-ios-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosSix: file(relativePath: { eq: "poc/ios/poc-ios-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosSeven: file(relativePath: { eq: "poc/ios/poc-ios-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosEight: file(relativePath: { eq: "poc/ios/poc-ios-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocIosNine: file(relativePath: { eq: "poc/ios/poc-ios-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pocAndroidOne: file(relativePath: { eq: "poc/android/poc-android-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocAndroidTwo: file(relativePath: { eq: "poc/android/poc-android-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocAndroidThree: file(relativePath: { eq: "poc/android/poc-android-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocAndroidFour: file(relativePath: { eq: "poc/android/poc-android-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocAndroidFive: file(relativePath: { eq: "poc/android/poc-android-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pocAndroidSix: file(relativePath: { eq: "poc/android/poc-android-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid
          }
        }
      }


    }
  `)

  return (
    <Grid>
      <ProjectHeader 
        title="Point of Care"
        subTitle="EHR - Care Coordination & Workflow Efficiency (EPIC for senior living)"
      />
      
      <ProjectInfo 
        when="2016 - 2018"
        platform="Mobile Native (iOS, Android) + Web"
        team="VP of Product, 2 PMs, 2 Designers"
        role="UI/UX, User Testing, Analytics, Prototyping"
        tools="Sketch, Invision, Abstract, Framer, Origami Studio, Jira, Confluence, Heap Analytics"

        symbolPlatform="📱"
      />


      <InfoGrid>
        <div className="company-info">
          <span>The Caremerge Point of Care</span> app gives clinical and non-clinical users in LTPAC and senior living settings the flexibility to document resident health and wellness information in real time, without requiring access to a desktop computer. 
        </div>

        <div className="background">
          <p className="background-title">Background</p>
          <p className="background-text">
            As one of two designers in a small early-stage startup, I juggled many tasks. I worked
            on this app over a period of two years. I was originally onboarded by another designer and after
            working with them for a couple of months, I took over as Lead Designer for all platforms.
          </p>
        </div>

        <div className="background">
          <p className="background-title">Noteable Achievements & Highlights</p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Spearheaded Heap Analytics setup & integration into production for web & iOS. Then collaborated with PMs and taught them how to use funnels for measuring the metrics they wanted.
          This led to many product insights and drop-off points that used to be a mystery.</p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Created Design Ops system with Abstract and onboarded project managers which led to faster, more iterative design reviews. </p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Created and maintained iOS style guide / design system.</p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Led the design of porting to Android operating system via Material Design guidelines.</p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Participated in monthly on sites to senior living communities where I worked hands-on with CNAs to improve features and troubleshoot any issues they were having.</p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Created sketch plugin to fill designs with accurate data like medications and various senior living medical terms & codes.</p>
          <p className="background-text"><span className="star">⭐️</span><span>-</span>Worked very close with engineers to ensure the designs that were shipped were heavily QA'd to original design specs.</p>
        </div>
      </InfoGrid>


      <ProjectSubHeaderBlank 
        title="Selected Designs"
      />


      <ProjectShowcaseCard>
        <Images fluid={data.pocIosOne.childImageSharp.fluid} />
        <Images fluid={data.pocIosTwo.childImageSharp.fluid} />
        <Images fluid={data.pocIosThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.pocIosFour.childImageSharp.fluid} />
        <Images fluid={data.pocIosFive.childImageSharp.fluid} />
        <Images fluid={data.pocIosSix.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.pocIosSeven.childImageSharp.fluid} />
        <Images fluid={data.pocIosEight.childImageSharp.fluid} />
        <Images fluid={data.pocIosNine.childImageSharp.fluid} />
      </ProjectShowcaseCard>

      <ProjectShowcaseCard>
        <Images fluid={data.pocAndroidOne.childImageSharp.fluid} />
        <Images fluid={data.pocAndroidTwo.childImageSharp.fluid} />
        <Images fluid={data.pocAndroidThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.pocAndroidFour.childImageSharp.fluid} />
        <Images fluid={data.pocAndroidFive.childImageSharp.fluid} />
        <Images fluid={data.pocAndroidSix.childImageSharp.fluid} />
      </ProjectShowcaseCard>

      <FooterWithPadding />

    </Grid>
  )
}

export default PointOfCarePage




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;
`

const ProjectShowcaseCard = styled.div`
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);
  padding: 48px;
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12%;
  

  @media (max-width: 900px) {
    padding: 24px;
  }
  @media (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`

const Images = styled(Img)`
  /* max-width: 960px;
  margin: 0 auto;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.16)); */
  /* border: 1px solid #D7D6D7;
  border-radius: 10px; */
  box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.15);
  border-radius: .5rem;
`


const InfoGrid = styled.div`
  p {margin: 0}

  .company-info {
    font-weight: 400;
    font-size: clamp( 16px,2vw,18px);
    line-height: 1.6;
    margin: 32px 0px;
    padding: 0px 0px 0px 11px;
    border-left: 3px solid var(--black-blue);
    color: var(--black-blue);
    span {
      font-weight: bold;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 650px) {
      p .star {
      display: none;
    }
  }

  .background {
    .background-title {
      font-weight: 600;
      font-size: clamp( 16px,2vw,18px);
      padding: 0px 0px 16px 0px;
      color: #0c0c1d;
    }
    .background-text {
      font-weight: 400;
      font-size: clamp( 16px,2vw,18px);
      line-height: 1.6;
      color: var(--off-black);
			padding-bottom: 2.5%;
      span {
        font-weight: 900;
      }
    }
    p .star {
      padding-right: 1%;
    }
    p span {
      font-weight: 900;
      padding-right: .5%;
    }
    @media (max-width: 1200px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 850px) {
      padding-left: 48px;
      padding-right: 48px;
    }
    @media (max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`

