import * as React from "react"
import styled from 'styled-components';


const ProjectSubHeaderBlank = (props) => {
  return (
    <Grid>
        <h2 className="subheader__title">{props.title}</h2>
    </Grid>
  )
}

export default ProjectSubHeaderBlank


const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr .2fr;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
  

  /* @media (max-width: 440px) {
    flex-direction: column;
  } */
  @media (max-width: 680px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
  }

  .subheader__title {
    padding-bottom: 16px;
    font-weight: 900;
    font-size: clamp(100%, 36px + 2vw, 48px);
    color: var(--black-blue);
  }
  @media (max-width: 1200px) {
    margin-left: 48px;
    margin-right: 48px;
  }
  @media (max-width: 850px) {
    margin-left: 48px;
    margin-right: 48px;
  }
  @media (max-width: 650px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`
